<template>
  <div style="width: 100%;" @click="handleClearSelection">
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :content-api="contentAPI"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar :native="true">
          <div class="contentCardsContainer">
            <el-row type="flex" class="list-cards" v-loading="loading">
              <el-col
                :xl="4"
                :lg="6"
                :md="6"
                :sm="6"
                :xs="8"
                v-for="(prompt, index) in prompts"
                :key="index"
                class="contentCol"
              >
                <ContentCard
                  :card-class="{ selected: selectedItemIndex === index }"
                  icon="icon-microphone.svg"
                  :card-text="prompt.prompt_name"
                  :show-delete="false"
                  @select="handleSelect(index, prompt)"
                  @edit="handleEdit(index, prompt)"
                ></ContentCard>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentCard from "@/views/build/content/mixins/ContentCard";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "QForMePromptsList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentCard
  },
  data() {
    return {
      sortByColumns: ["prompt_name", "prompt_id"]
    };
  },

  computed: {
    ...mapState("prompts", {
      prompts: state => state.qfm_prompts,
      loading: state => state.loading
    })
  },

  methods: {
    ...mapActions("prompts", {
      contentAPI: "getQfmPrompts"
    })
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";
</style>
