<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Name") }}</div>
    <div class="details">{{ contentForm.prompt_name }}</div>

    <div class="titles">{{ __("Caller Intro TTS") }}</div>
    <div class="details" style="padding-bottom: 20px">
      <audio-player
        class="audio-player"
        :simplePlayer="true"
        :show-seek-bar="true"
        icon-play="/icons/play1.svg"
        :file="contentForm.default_voice_audio_url"
      />
    </div>

    <el-button
      @click="handleEditBtnClick"
      class="editBtn"
      :disabled="!canWrite()"
      >{{ __("Edit Prompt") }}</el-button
    >
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <div style="max-height: 100vh;">
        <el-form
          ref="contentForm"
          :rules="rules"
          :model="contentForm"
          label-position="top"
          size="large"
        >
          <el-row type="flex" style="padding-top: 20px">
            <el-col :span="12" :offset="6">
              <page-header :title="__('Edit Prompt')" />
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item prop="prompt_name" :label="__('Name')">
                <el-input
                  v-model="contentForm.prompt_name"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                prop="default_voice_audio_url"
                :label="__('Audio file')"
              >
                <br />
                <prompt-voice-edit
                  :voice-name="contentForm.default_voice_name"
                  :file-url="contentForm.default_voice_audio_url"
                  :file-path="contentForm.default_voice_file_path"
                  :tts_value_prop="contentForm.default_tts_value"
                  :voice-id="-1"
                  @update-audio-url="handleURLUpdate($event)"
                  :folder="folder"
                  @deleteUploadedAudio="deleteUploadedAudio"
                  :file-name="contentForm.default_voice_uploaded_file_name"
                  :audio-file-only="true"
                ></prompt-voice-edit>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div
          slot="footer"
          style="display: flex;margin-left: 25%; margin-bottom: 20px;"
        >
          <el-button type="primary" @click="submitForm" class="submitBtn"
            >{{ __("Update") }}
          </el-button>
          <el-button @click="handleCancel" class="cancelBtn">{{
            __("Cancel")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapState, mapActions, mapGetters } from "vuex";
import PromptVoiceEdit from "@/views/build/content/prompts/components/PromptVoiceEdit";
import _ from "lodash";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  mixins: [BaseContentInfoPanel],
  components: {
    PageHeader,
    AudioPlayer,
    PromptVoiceEdit
  },
  data() {
    return {
      // rules: {
      //   default_voice_audio_url: [
      //     { required: true, trigger: "blur", message: "Audio file is required" }
      //   ]
      // },
      ttsVoiceId: "",
      isSubmitting: false,
      folder: "qforme/prompt_audio"
    };
  },

  computed: {
    ...mapState("tasks", {
      loadingVoices: state => state.loading,
      languages: state => state.languages
    }),
    ...mapGetters("variables", {
      variables: "variables"
    })
  },
  methods: {
    ...mapActions("prompts", {
      createPrompt: "createPrompt",
      updatePrompt: "updatePrompt",
      cleanUpPrompts: "cleanUpPrompts"
    }),

    addVoice() {
      let voiceList = _.flatMap(this.languages);
      let voice = _.find(
        voiceList,
        voice => voice.tts_voice_id === this.ttsVoiceId
      );
      if (
        _.some(this.contentForm.prompt_values, prompt_value => {
          return prompt_value.tts_voice_id === this.ttsVoiceId;
        })
      ) {
        this.$message.error(
          __("The voice :voice_name is already in the list", {
            voice_name: voice.voice_name
          })
        );
      } else {
        this.contentForm.prompt_values.push({
          tts_voice_id: this.ttsVoiceId,
          tts_value: "",
          file_url: "",
          voice_name: voice.voice_name,
          file_path: ""
        });
      }
      this.ttsVoiceId = "";
    },
    removeVoice(voiceId) {
      let prompt_value_index = _.findIndex(
        this.contentForm.prompt_values,
        prompt_value => {
          return prompt_value.tts_voice_id === voiceId;
        }
      );
      if (prompt_value_index !== -1) {
        this.contentForm.prompt_values.splice(prompt_value_index, 1);
      }
    },
    deleteUploadedAudio(voiceId) {
      if (voiceId === -1) {
        this.contentForm.default_voice_audio_url = "";
        this.contentForm.default_voice_file_path = "";
      } else {
        let prompt_value = _.find(
          this.contentForm.prompt_values,
          prompt_value => {
            return prompt_value.tts_voice_id === voiceId;
          }
        );
        if (prompt_value) {
          prompt_value.file_path = "";
          prompt_value.file_url = "";
        }
      }
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createPrompt : this.updatePrompt;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("Prompt added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Prompt updated successfully")
                  });
              this.cleanUpPrompts();
              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              // console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },
    handleTTSUpdate({ tts_value, voiceId }) {
      if (voiceId === -1) {
        this.contentForm.default_tts_value = tts_value;
      } else {
        let prompt_value = _.find(
          this.contentForm.prompt_values,
          prompt_value => {
            return prompt_value.tts_voice_id === voiceId;
          }
        );
        if (prompt_value) {
          prompt_value.tts_value = tts_value;
        }
      }
    },
    handleURLUpdate({ url, voiceId, path, uploadedFileName }) {
      if (voiceId === -1) {
        this.contentForm.default_voice_audio_url = url;
        this.contentForm.default_voice_file_path = path;
        this.contentForm.default_voice_uploaded_file_name = uploadedFileName;
      } else {
        let prompt_value = _.find(
          this.contentForm.prompt_values,
          prompt_value => {
            return prompt_value.tts_voice_id === voiceId;
          }
        );
        if (prompt_value) {
          prompt_value.file_url = url;
          prompt_value.audio_file_path = path;
          prompt_value.uploaded_file_name = uploadedFileName;
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";
.addVoice {
  ::v-deep .el-input__inner {
    background: white;
    text-align: center;
    color: $content-theme-color;
    border: $content-theme-color 2px solid;
    &::placeholder {
      color: $content-theme-color;
      font-weight: bold;
    }
  }

  ::v-deep .el-input.is-focus {
    border-color: $content-theme-color;
    outline: none;
  }
  ::v-deep .el-input__suffix {
    display: none;
  }
}

.audio-player {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-color: #0b8e6b;
  color: #454545;

  ::v-deep .audio-bar-contents {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.7rem;
    padding-left: 5px;
    padding-right: 5px;

    .control-group {
      display: flex;
      flex-direction: row;
      min-width: 10px;
      align-items: center;
    }

    .audio-bar-content {
      padding-left: 0;
      padding-right: 3px;
    }

    .audio-control {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .audio-progress {
      padding-left: 5px;
      padding-right: 10px;
      flex: 1;

      &.disabled {
        ::v-deep .el-slider__button {
          background-color: #a0a8b5 !important;
          border-color: #a0a8b5 !important;
        }

        ::v-deep .el-slider__runway {
          height: 1px;
          background-color: #454545;

          ::v-deep .el-slider__bar {
            height: 1px;
            background-color: #4db3f6;
          }

          ::v-deep .el-slider__button {
            width: 3px;
            height: 3px;
            border-radius: 2px;
          }
        }
      }

      ::v-deep .el-slider__runway {
        height: 3px;
        background-color: #454545;
        ::v-deep .el-slider__bar {
          height: 3px;
          background-color: #4db3f6;
        }

        ::v-deep.el-slider__button-wrapper {
          height: 34px;
          width: 12px;

          ::v-deep .el-tooltip {
            vertical-align: middle;
          }
        }
        ::v-deep .el-slider__button {
          width: 10px;
          height: 10px;
          border-radius: 8px;
          border-width: 1px;
          border-color: #454545;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.editContentItemModal {
  padding: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
